<template>
  <b-modal
    id="modal__SpecificationCopy"
    title="Copy Specification"
    size="lg"
    lazy
    no-fade
    @show="onShow"
    @ok.prevent="onSubmit"
    @cancel="onCancel"
  >
    <h6 class="d-inline-flex justify-content-between w-100 text-primary mb-0">
      {{ createSpec ? 'New Specification details' : 'Select target Specification to copy into' }}

      <b-form-checkbox v-model="createSpec" title="Create New Specification" switch>
        <span>Create New Specification</span>
      </b-form-checkbox>
    </h6>

    <SpecificationForm v-if="createSpec" v-model="fields" />

    <SpecificationPicker v-else v-model="targetSpecification" />

    <h6 class="mt-2 text-primary">
      Relationships to other objects
    </h6>
    <b-form>
      <b-container>
        <p>Copy links to...</p>
        <b-row>
          <b-col>
            <b-form-checkbox-group
              v-model="selectedLinks"
              style="column-count: 2"
              class="custom-control-info"
              :options="linkOptions"
              switches
              stacked
              :checked="selectedLinks"
            />
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <template v-slot:modal-footer="{ok, cancel}">
      <div v-if="loading" class="font-small-2">
        Copying may take a few minutes...
      </div>
      <div>
        <b-button variant="outline-secondary" class="mr-50" @click="cancel()">
          Dismiss
        </b-button>
        <b-button variant="success" :disabled="loading" @click="ok()">
          <span v-if="loading">
            <b-spinner small type="grow" />
            Copying...
          </span>
          <span v-else>
            Create Copy
          </span>
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>

import SpecificationForm from '@/views/Specifications/components/SpecificationForm.vue'
import store from '@/store'
import { computed, ref } from '@vue/composition-api'
import SpecificationPicker from '@/components/Specifications/SpecificationPicker.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'CopySpecificationModal',
  components: {
    SpecificationPicker,
    SpecificationForm,
  },
  setup(props, context) {
    const loading = ref(false)
    const fields = ref({
      model: '',
      title: '',
      description: '',
      prefix: '',
    })
    const linkOptions = [
      { text: 'Components', value: 'ALLOCATED_TO:Component' },
      { text: 'Interfaces', value: 'ALLOCATED_TO:Interface' },
      { text: 'Behaviour Nodes', value: 'ALLOCATED_TO:BehaviourNode' },
      { text: 'Releases', value: 'ALLOCATED_TO:Release' },
      { text: 'Issues', value: 'HAS_ISSUE' },
      { text: 'Notes', value: 'HAS_NOTE' },
      { text: 'Tests', value: 'TESTED_BY' },
      { text: 'Other Requirements (Traces)', value: 'TRACED_TO' },
    ]

    const createSpec = ref(false)
    const targetSpecification = ref(null)
    const selectedLinks = ref([])

    const specification = computed(() => store.state.specification.selectedSpecObj)
    const specifications = computed(() => store.state.specifications.specifications)

    const onShow = () => {
      const { title, description, prefix } = specification.value
      selectedLinks.value = linkOptions.map(link => link.value)
      fields.value = {
        model: store.state.model.id,
        title: `Copy of ${title}`,
        description: `Copy of ${description}`,
        prefix: `${prefix}COPY-`,
      }
    }

    const onSubmit = () => {
      loading.value = true
      let errorMsg = null
      if (!createSpec.value) {
        if (!targetSpecification.value) {
          errorMsg = 'No specification selected. Please select a Specification to copy into'
        } else if (targetSpecification.value.id === specification.value.id) {
          errorMsg = 'Can not copy Specification into itself. Please select a different Specification'
        }
      } else if (specifications.value.map(spec => spec.prefix).includes(fields.value.prefix)
            || specifications.value.map(spec => spec.title).includes(fields.value.title)) {
        errorMsg = 'Title or prefix conflicts with existing Specification.'
      }

      if (errorMsg) {
        context.root.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to copy Specification',
            icon: 'XIcon',
            variant: 'danger',
            text: errorMsg,
          },
        })
        loading.value = false
        return
      }

      const payload = {
        create_spec: createSpec.value,
        copy_relationship_types: selectedLinks,
      }
      if (createSpec.value) {
        payload.fields = fields.value
      } else {
        payload.target_spec = targetSpecification.value.id
      }
      store.dispatch('specification/copySpecification', payload)
        .then(data => {
          loading.value = false
          if (data) {
            context.emit('submit')
            context.root.$bvModal.hide('modal__SpecificationCopy')
          }
        })
    }

    const onCancel = () => {
      loading.value = false
      fields.value = {
        model: '',
        title: '',
        description: '',
        prefix: '',
        jira_project: '',
      }
    }

    return {
      loading,
      fields,
      linkOptions,

      createSpec,
      targetSpecification,
      selectedLinks,

      onShow,
      onSubmit,
      onCancel,
    }
  },
}
</script>
